
'use strict';

var windowIsTurbolinked = 'Turbolinks' in window;
var isSecure = location.protocol === 'https:';
var isCalled = false;

$(document).ready(function() {
  if (document.addEventListener) {
    return document.addEventListener('DOMContentLoaded', isReady(), false);
  }

  // Old browsers IE < 9
  if (window.addEventListener) {
    window.addEventListener(eventName('load'), isReady, false);
  } else if (window.attachEvent) {
    window.attachEvent(eventName('onload'), isReady);
  }
});

var cookiesEu = {
  init: function() {
    var cookieBtnAccept = document.querySelector('.js-cookies-eu-ok');
    var cookieBtnReject = document.querySelector('.js-cookies-eu-reject');
    var cookieChoicesBtnSave = document.querySelector('.js-choices-cookies-save');

    // Banner options
    if (cookieBtnAccept) {this.addListener(cookieBtnAccept, 'accept_all'); }
    if (cookieBtnReject) { this.addListener(cookieBtnReject, 'reject_all'); }
    if (cookieChoicesBtnSave) { this.addListener(cookieChoicesBtnSave, 'save_choices'); }

    // clear turbolinks cache so cookie banner does not reappear
    windowIsTurbolinked && window.Turbolinks.clearCache();
  },

  addListener: function(target, typeListener) {
    // Support for IE < 9
    if (target.attachEvent) {
      if(typeListener == 'accept_all'){ target.attachEvent('onclick', this.setAcceptCookie); }
      else if(typeListener == 'save_choices'){ target.attachEvent('onclick', this.setAcceptSavedChoices); }
      else{ target.attachEvent('onclick', this.setRejectCookie); }
    } 
    else {
      if(typeListener == 'accept_all'){ target.addEventListener('click', this.setAcceptCookie, false); }
      else if(typeListener == 'save_choices'){ target.addEventListener('click', this.setAcceptSavedChoices, false); }
      else{ target.addEventListener('click', this.setRejectCookie, false); }
    }
  },

  setAcceptCookie: function() {
    Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: isSecure });
    Cookies.set('cookie-analytics', true, { path: '/', expires: 365, secure: isSecure });
    Cookies.set('cookie-strictly-necessary', true, { path: '/', expires: 365, secure: isSecure });
    
    var container = document.querySelector('.js-cookies-eu');

    if (container) { container.parentNode.removeChild(container); }
  },

  setRejectCookie: function() {
    Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: isSecure });
    Cookies.set('cookie-analytics', false, { path: '/', expires: 365, secure: isSecure });;
    Cookies.set('cookie-strictly-necessary', true, { path: '/', expires: 365, secure: isSecure });

    var container = document.querySelector('.js-cookies-eu');

    window['ga-disable-' + container.getAttribute('trackerid')] = true;

    container.parentNode.removeChild(container);
  },

  // FOR Inside Settings options
  setAcceptSavedChoices: function() {
    // var toggleAnalitics = document.getElementById('switch-analytics');

    // if(toggleAnalitics.classList.contains("active")){ 
      Cookies.set('cookie-analytics', true, { path: '/', expires: 365, secure: isSecure });
    // } else{ 
    //   Cookies.set('cookie-analytics', false, { path: '/', expires: 365, secure: isSecure });
    // }

    Cookies.set('cookie_eu_consented', true, { path: '/', expires: 365, secure: isSecure });
    Cookies.set('cookie-strictly-necessary', true, { path: '/', expires: 365, secure: isSecure });

    var container = document.querySelector('.js-cookies-eu');
    container.parentNode.removeChild(container);
  },
};

function isReady() {
  // return early when cookiesEu.init has been called AND Turbolinks is NOT included
  // when Turbolinks is included cookiesEu.init has to be called on every page load
  if (isCalled && !windowIsTurbolinked) {
    return
  }
  isCalled = true;
  cookiesEu.init();
}

// Toogle Switch functionality
function cookieToggleSwitch(idField, isBlocked) {
  var toggle = document.getElementById(idField);

  if (isBlocked == false){
    if(toggle.classList.contains("active")){ 
      toggle.classList.remove("active");  
    } else{ 
      toggle.classList.add("active");  
    }
  }
}

function activeToggleSwitches(allowAll) {
  var toggleAnalitics = document.getElementById('switch-analytics');
  
  if(allowAll == true){ 
    toggleAnalitics.classList.add("active");  
  } else{ 
    toggleAnalitics.classList.remove("active");  
  }
}

